import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from '@Store/'
import { useInView } from 'react-intersection-observer'
import { AnimateSlideInWrap, AnimateSlideInContent } from './index.style'
import { animation } from '@styles/vars/animation.style'

const AnimateSlideIn = ({
  direction = 'bottom',
  duration = 1,
  delay = 0,
  triggerOnce = true,
  animate = true,
  forceAnimate = false,
  animateOut = false,
  forceAnimateOut = false,
  rootMargin = animation.rootMargin,
  block = false,
  children,
}) => {
  const [ref, inView] = useInView({
    rootMargin: rootMargin,
    triggerOnce,
  })
  const [store] = useStore()
  const { showPageMask } = store

  return (
    <AnimateSlideInWrap ref={ref} block={block}>
      <AnimateSlideInContent
        inView={
          (forceAnimate && !forceAnimateOut) ||
          (inView && animate && !showPageMask && !forceAnimateOut)
        }
        animateOut={animateOut || forceAnimateOut}
        direction={direction}
        duration={duration}
        delay={delay}
      >
        {children}
      </AnimateSlideInContent>
    </AnimateSlideInWrap>
  )
}

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  forceAnimate: PropTypes.bool,
  animateOut: PropTypes.bool,
  forceAnimateOut: PropTypes.bool,
  block: PropTypes.bool,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

export default AnimateSlideIn
