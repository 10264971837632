import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Logo = ({
  label = 'logo',
  width = 181,
  height = 41,
  fill = colors.dark,
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 181 41"
      aria-labelledby={label}
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <title id={label}>Sparq</title>
      <path
        d="M10.5013 29.1227C7.30282 29.1227 4.22294 27.8992 1.97282 25.8058L0 28.6486C2.44698 30.9389 6.39685 32.2407 10.5013 32.2407C15.791 32.2407 19.5419 29.3979 19.5419 25.2533C19.5419 21.858 17.095 20.0418 13.778 19.2121L8.25113 17.7917C6.11956 17.2795 4.65899 16.6465 4.65899 14.9087C4.65899 12.8956 6.63393 11.7103 9.6736 11.7103C12.201 11.7103 14.8067 12.6183 17.095 14.6716L19.1884 12.0659C16.6208 9.89408 13.4626 8.63037 9.91067 8.63037C4.85797 8.63037 1.34414 11.3144 1.34414 15.1055C1.34414 18.6194 3.87156 20.1201 7.58224 20.988L13.1091 22.33C14.847 22.764 16.2271 23.5535 16.2271 25.4883C16.2271 27.859 13.6595 29.1227 10.5013 29.1227Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7545 19.6456V13.289L32.8213 12.2221H37.2834C41.1529 12.2221 42.9288 13.5641 42.9288 15.973C42.9288 18.3015 41.1529 19.6456 37.2834 19.6456H31.7545ZM37.3596 22.7255C43.1236 22.7255 46.3622 20.3568 46.3622 15.9751C46.3622 11.5934 43.1257 9.18457 37.3596 9.18457H28.4375V31.6879H31.7545V22.7255H37.3596V22.7255Z"
        fill={fill}
      />
      <path
        d="M71.6797 31.6879H68.0875L61.1784 13.4477H60.1116L53.1982 31.6879H49.6484L58.5727 9.18457H62.7956L71.6797 31.6879Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.2409 12.2244C91.1104 12.2244 92.9265 13.5664 92.9265 15.9753C92.9265 18.3037 91.1104 19.6479 87.2409 19.6479H81.8326V13.2891L82.8994 12.2223H87.2409V12.2244ZM81.8326 22.7257H86.7668L93.2017 31.688H97.1897L90.9918 23.1596V22.3298C94.4654 21.5 96.3218 19.3283 96.3218 15.9732C96.3218 11.5915 93.1234 9.18262 87.3192 9.18262H78.5156V31.688H81.8326V22.7257V22.7257Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.114 11.7505C108.652 11.7505 106.442 15.658 106.442 20.4355C106.442 25.2131 108.652 29.0825 113.114 29.0825C113.667 29.0825 114.219 29.0042 114.693 28.8857L115.049 27.9776L112.799 24.9379L115.405 23.0434L117.695 26.1614H118.406C119.355 24.6225 119.827 22.6476 119.827 20.4377C119.827 15.658 117.577 11.7505 113.114 11.7505ZM120.064 29.3577L122.748 33.1086L120.142 35.0031L117.418 31.2924C116.154 31.8851 114.693 32.2407 113.114 32.2407C106.917 32.2407 102.969 27.0293 102.969 20.4355C102.969 13.8037 106.917 8.63037 113.114 8.63037C119.352 8.63037 123.3 13.8016 123.3 20.4355C123.3 24.0298 122.117 27.2261 120.064 29.3577Z"
        fill={fill}
      />
    </svg>
  )
}

export default Logo
