exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-newrooms-expand-js": () => import("./../../../src/pages/newrooms-expand.js" /* webpackChunkName: "component---src-pages-newrooms-expand-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-templates-news-room-js": () => import("./../../../src/templates/newsRoom.js" /* webpackChunkName: "component---src-templates-news-room-js" */),
  "component---src-templates-standard-content-js": () => import("./../../../src/templates/standardContent.js" /* webpackChunkName: "component---src-templates-standard-content-js" */)
}

