import { useEffect } from 'react'
import { updateScroll, useStore } from '../../Store'
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from 'locomotive-scroll'
import { animation } from '../../styles/vars/animation.style'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const SmoothScroll = ({ callbacks, desktop }) => {
  const [, dispatch] = useStore()

  useEffect(() => {
    window.scroll = new LocomotiveScroll({
      el: document.getElementById('scroll-container'),
      smooth: true,
      smartphone: {
        smooth: false,
      },
      direction: 'vertical',
      getDirection: true,
      touchMultiplier: 2.5,
      firefoxMultiplier: 70,
      lerp: 0.1,
      repeat: false,
      class: 'is-inview',
      reloadOnContextChange: true,
    })

    updateScroll(dispatch, window.scroll)

    const timeout = setTimeout(() => {
      window.scroll.update()
    }, animation.maskHideDuration * 1000)

    // We only need to sync up the scroll position with Locomotive if we're on desktop, so
    // I've copied the check from their source so we don't bother on mobile.
    const isMobile =
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
      window.innerWidth < 1024

    if (!isMobile) {
      ScrollTrigger.defaults({
        scroller: '[data-scroll-container]',
        markers: false,
        pinType: 'transform',
      })

      window.scroll.on('scroll', instance => {
        ScrollTrigger.update()
        document.documentElement.setAttribute(
          'data-scrolling',
          instance.direction
        )
      })

      ScrollTrigger.scrollerProxy('[data-scroll-container]', {
        scrollTop(value) {
          return arguments.length
            ? window.scroll.scrollTo(value, 0, 0)
            : window.scroll.scroll.instance.scroll.y
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          }
        },
        pinType: 'transform',
      })

      ScrollTrigger.addEventListener('refresh', () => window.scroll.update())
      ScrollTrigger.refresh()
    } else {
      document.body.classList.add('no-locomotive')
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      if (window.scroll) {
        window.scroll.destroy()
        updateScroll(dispatch, null)
      }
    }
  }, [callbacks.pathname, dispatch, desktop])

  return null
}

export default SmoothScroll
