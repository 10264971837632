export const font = {
  primary: {
    family: 'Plain',
    weight: {
      light: 100,
      medium: 500,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
