import React, { useState, useEffect } from 'react'
import {
  useStore,
  setHeader,
  showNewsletterForm,
  showCheckoutForm,
  showDiagnosticsModal,
} from '@Store/'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import PageTransitionLink from '../PageTransitionLink'
import Container from '../Container'
import {
  HeaderContainer,
  HeaderPreLogo,
  HeaderLogo,
  HeaderLogoText,
  HeaderLogoShapes,
  HeaderMain,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNav,
  HeaderToggle,
  HeaderToggleItem,
  HeaderToggleWrap,
  HeaderMobileIcon,
  HeaderMobileIconLine,
  HeaderMobileIconWrap,
} from './index.style'
import Logo from '../svgs/Logo'
import LogoShape from '../svgs/LogoShape'
import Button from '@components/Button'
import { graphql, useStaticQuery } from 'gatsby'

const Header = () => {
  const [store, dispatch] = useStore()
  const { showPageMask, header, pathname } = store

  const { contentfulGlobals } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        salesFunnelMode
      }
    }
  `)

  const { salesFunnelMode } = contentfulGlobals
  const newsletterMode = salesFunnelMode === 'Newsletter'

  const [scrolled, setScrolled] = useState(false),
    [down, setDown] = useState(true),
    [showLink, setShowLink] = useState(true),
    [showToggle, setShowToggle] = useState(true),
    [activeToggle, setActiveToggle] = useState(null),
    [showDiagnosticsButton, setShowDiagnosticsButton] = useState(
      pathname === '/'
    ),
    [showMobileMenu, setShowMobileMenu] = useState(false),
    [hasMobileMenu, setHasMobileMenu] = useState(false)

  useEffect(() => {
    if (!store.scroll) return

    let previousScroll = 0,
      finalScrollTimeout = null

    const checkFinalScroll = () => {
      if (store.scroll.scroll.instance.scroll.y <= 10) {
        setScrolled(false)
        setDown(true)
      }
    }

    const scrollUpdate = () => {
      if (finalScrollTimeout) clearTimeout(finalScrollTimeout)

      if (store.scroll.scroll.instance.scroll.y > 10) {
        setScrolled(true)

        if (store.scroll.scroll.instance.scroll.y > previousScroll + 5) {
          setShowMobileMenu(false)
          setDown(true)
          previousScroll = store.scroll.scroll.instance.scroll.y
        } else if (store.scroll.scroll.instance.scroll.y < previousScroll - 5) {
          setShowMobileMenu(false)
          setDown(false)
          previousScroll = store.scroll.scroll.instance.scroll.y
        }
      } else {
        setScrolled(false)
        setDown(true)
        previousScroll = store.scroll.scroll.instance.scroll.y
      }

      finalScrollTimeout = setTimeout(checkFinalScroll, 750)
    }

    store.scroll.on('scroll', scrollUpdate)

    return () => {
      store.scroll.off('scroll', scrollUpdate)
    }
  }, [store.scroll])

  useEffect(() => {
    setScrolled(false)
    setDown(true)
    setHeader(dispatch, `dark`)
    setShowLink(pathname !== '/confirmation/')
    setShowToggle(pathname === '/')
  }, [pathname, dispatch])

  useEffect(() => {
    // Defer switching of CTAs to ensure page mask is covering the page before a change
    if (showPageMask) return
    setShowDiagnosticsButton(pathname === '/')
  }, [showPageMask, pathname])

  useEffect(() => {
    if (typeof window === 'undefined') return

    setHasMobileMenu(window.innerWidth < 1024)

    const handleResize = () => {
      setHasMobileMenu(window.innerWidth < 1024)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const scrollToTop = () => {
    if (window.scroll) window.scroll.scrollTo(0)
  }

  return (
    <>
      <HeaderContainer>
        <Container>
          <HeaderMain mobileOpen={showMobileMenu}>
            <HeaderLogo>
              <HeaderPreLogo
                onClick={() => {}}
                id={`pre-logo`}
                aria-label={`Top of page`}
              />
              {pathname === '/' ? (
                <button onClick={scrollToTop}>
                  <HeaderLogoShapes
                    side={'left'}
                    show={true}
                    colorMode={header}
                  >
                    <LogoShape />
                    <LogoShape />
                  </HeaderLogoShapes>
                  <HeaderLogoText show={!scrolled}>
                    <Logo />
                  </HeaderLogoText>
                </button>
              ) : (
                <PageTransitionLink to="/">
                  <HeaderLogoShapes
                    side={'left'}
                    show={true}
                    colorMode={header}
                  >
                    <LogoShape />
                    <LogoShape />
                  </HeaderLogoShapes>
                  <HeaderLogoText show={!scrolled}>
                    <Logo />
                  </HeaderLogoText>
                </PageTransitionLink>
              )}
            </HeaderLogo>

            {/* <HeaderToggleWrap>
              <AnimateSlideIn
                animate={!showPageMask && showToggle}
                forceAnimate={hasMobileMenu}
                forceAnimateOut={
                  scrolled &&
                  (header !== 'light' || pathname !== '/') &&
                  !hasMobileMenu
                }
                delay={0.2}
              >
                <HeaderToggle
                  colorMode={header}
                  diagnostics={showDiagnosticsButton}
                  hovered={activeToggle}
                >
                  <HeaderToggleItem active={!showDiagnosticsButton}>
                    <PageTransitionLink
                      to="/"
                      onMouseEnter={() => setActiveToggle(0)}
                      onMouseLeave={() => setActiveToggle(null)}
                      onClick={() => setShowMobileMenu(false)}
                    >
                      Membership
                    </PageTransitionLink>
                  </HeaderToggleItem>
                  <HeaderToggleItem active={showDiagnosticsButton}>
                    <PageTransitionLink
                      to="/diagnostics/"
                      onMouseEnter={() => setActiveToggle(1)}
                      onMouseLeave={() => setActiveToggle(null)}
                      onClick={() => setShowMobileMenu(false)}
                    >
                      Diagnostics Device
                    </PageTransitionLink>
                  </HeaderToggleItem>
                </HeaderToggle>
              </AnimateSlideIn>
            </HeaderToggleWrap> */}

            <HeaderNav show={showLink}>
              <HeaderMenu>
                {!showDiagnosticsButton ? (
                  <HeaderMenuItem colorMode={header}>
                    <AnimateSlideIn
                      animate={!showPageMask && down}
                      animateOut={!down}
                      delay={0.2}
                    >
                      <Button
                        variant={'secondary'}
                        size={'small'}
                        onClick={() =>
                          newsletterMode
                            ? showNewsletterForm(dispatch)
                            : showCheckoutForm(dispatch)
                        }
                        label={'Sign up to the SPARQ waiting list'}
                      >
                        Get SPARQ
                      </Button>
                    </AnimateSlideIn>
                  </HeaderMenuItem>
                ) : (
                  <HeaderMenuItem colorMode={header}>
                    <AnimateSlideIn
                      animate={!showPageMask && down}
                      animateOut={!down}
                      delay={0.2}
                    >
                      <Button
                        size={'small'}
                        onClick={() => showDiagnosticsModal(dispatch)}
                        label={'Order a SPARQ Diagnostics Device'}
                      >
                        Order Now
                      </Button>
                    </AnimateSlideIn>
                  </HeaderMenuItem>
                )}
              </HeaderMenu>

              <HeaderMobileIconWrap>
                <AnimateSlideIn
                  animate={!showPageMask && down}
                  animateOut={!down}
                  delay={0.2}
                >
                  <HeaderMobileIcon
                    open={showMobileMenu}
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                    colorMode={header}
                  >
                    <HeaderMobileIconLine />
                    <HeaderMobileIconLine />
                  </HeaderMobileIcon>
                </AnimateSlideIn>
              </HeaderMobileIconWrap>
            </HeaderNav>
          </HeaderMain>
        </Container>
      </HeaderContainer>
    </>
  )
}

export default Header
