import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'
import { clamp, getClampValue } from '../../styles/utils/conversion.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { ButtonContent, ButtonMain } from '@components/Button/index.style'
import { colors } from '@styles/vars/colors.style'
import {
  AnimateSlideInContent,
  AnimateSlideInWrap,
} from '@components/animation/AnimateSlideIn/index.style'
import { paddingMax, paddingMin } from '@components/Container/index.style'

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -1rem;
  margin-right: -1rem;
  position: relative;
  ${clamp('margin-top', 15, 50)};
  transform: translateY(${props => (props.mobileOpen ? 46 : 0)}px);
  transition: transform 1s ${easings.inOut.default};

  ${mq.tabletL} {
    margin-left: 0;
    margin-right: 0;
    transform: none;
  }
`

export const HeaderPreLogo = styled.button`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`

export const HeaderLogo = styled.div`
  ${clamp('height', 20, 41)}
  position: relative;
  ${clamp('width', 88, 181)}
  z-index: ${zIndex.mobileNavButtons};

  a,
  button {
    height: 100%;
    width: 100%;
  }

  button {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
`

export const HeaderLogoText = styled.div`
  height: 100%;
  ${clamp('left', 23, 52)};
  position: relative;
  width: 100%;

  path {
    transform: translateY(${props => (props.show ? 0 : 100)}%);
    transition: opacity 1s ${easings.inOut.default},
      transform 1s ${easings.inOut.default};

    &:nth-of-type(1) {
      transition-delay: 0s;
    }
    &:nth-of-type(2) {
      transition-delay: 0.05s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.1s;
    }
    &:nth-of-type(4) {
      transition-delay: 0.15s;
    }
    &:nth-of-type(5) {
      transition-delay: 0.2s;
    }
  }
`

export const HeaderLogoShapes = styled.div`
  font-size: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotateZ(-45deg);

  svg {
    ${clamp('height', 8, 17)}
    transform: translateX(${props => (props.show ? 0 : 100)}%);
    transition: transform 0.35s ${easings.inOut.default};
    ${clamp('width', 13, 27)}

    &:nth-child(2) {
      ${clamp('margin-top', 1, 1)};
      transform: rotateZ(180deg) translateX(${props => (props.show ? 0 : 100)}%);
    }
  }

  path {
    fill: ${props =>
      props.colorMode === `light` ? colors.light : colors.dark};
    transition: fill 0.5s ${easings.inOut.default};
  }
`

export const HeaderToggleWrap = styled.div`
  font-size: 1.8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${mq.tabletLMax} {
    font-size: 1.4rem;
    height: 5.2rem;
    left: 0;
    margin-left: ${clamp('margin-left', -paddingMin, -paddingMax)};
    top: -1.1rem;
    transform: translateY(-100%);
    width: calc(100% + ${getClampValue(paddingMin * 2, paddingMax * 2)});

    ${AnimateSlideInWrap},
    ${AnimateSlideInContent} {
      width: 100%;
    }
  }
`

export const HeaderToggle = styled.ul`
  display: flex;
  list-style: none;
  pointer-events: ${props => (props.show ? `auto` : `none`)};
  transition: opacity 1s ${easings.inOut.default};
  width: 100%;

  ${mq.tabletLMax} {
    background-color: ${colors.light};
  }

  ${mq.tabletL} {
    width: 39rem;
  }

  &:before,
  &:after {
    background-color: ${colors.lightgrey};
    bottom: 0;
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &:before {
    background-color: ${colors.orange};
    width: 50%;
    transform: translateX(
      ${props =>
        props.hovered === 1 || (props.diagnostics && props.hovered !== 0)
          ? 100
          : 0}%
    );
    transition: transform 1s ${easings.inOut.default};
    z-index: 2;
  }

  a {
    color: ${colors.dark};

    ${mq.tabletL} {
      color: ${props =>
        props.colorMode === `light` ? colors.light : colors.dark};
      transition: color 0.5s ${easings.inOut.default};

      &:hover {
        color: ${colors.orange};
      }
    }
  }
`

export const HeaderToggleItem = styled.li`
  padding: 1.5rem 0;
  pointer-events: ${props => (props.active ? `none` : `auto`)};
  text-align: center;
  width: 50%;

  a {
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%;
  }
`

export const HeaderNav = styled.nav`
  display: flex;
  gap: 0.5rem;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? `auto` : `none`)};
  transition: opacity 0.5s ${easings.inOut.default}
    ${props => (props.show ? `1s` : `0s`)};
`

export const HeaderMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  text-align: right;
`

export const HeaderMenuItem = styled.li`
  padding-right: 2rem;

  &:last-child {
    padding-right: 0;
  }

  ${PageTransitionLinkMain} {
    display: inline-flex;
  }

  ${ButtonContent} {
    color: ${props => (props.colorMode === `light` ? colors.light : ``)};
  }
`

export const HeaderBottomCTA = styled.div`
  bottom: 0;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(${props => (props.show ? 0 : 105)}%);
  transition: transform 1s ${easings.inOut.default};
  z-index: ${zIndex.header};

  ${mq.desk} {
    display: block;
  }

  ${ButtonMain} {
    border-radius: 0;
    width: 100%;
  }
`

export const HeaderMobileIconWrap = styled.div`
  height: 3.6rem;
  width: 3.6rem;
`

export const HeaderMobileIconLine = styled.span`
  display: block;
  height: 0.1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
`

export const HeaderMobileIcon = styled.button`
  background: transparent;
  border: ${colors.orange} 1px solid;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 3.6rem;
  width: 3.6rem;
  position: relative;

  ${mq.tabletL} {
    display: none;
  }

  ${HeaderMobileIconLine} {
    background-color: ${props =>
      props.colorMode === `light` ? colors.light : colors.dark};
    transform-origin: 50% 50%;
    transition: transform 0.5s ${easings.inOut.default}${props => (props.open ? `` : `, margin-top 0.5s 0.5s`)},
      background-color 0.5s ${easings.inOut.default};
    will-change: transform;

    &:first-child {
      ${props => (props.open ? `` : `margin-top: -0.3rem;`)};
      transform: translateX(-50%)
        ${props => (props.open ? `rotateZ(45deg)` : ``)};
    }

    &:last-child {
      ${props => (props.open ? `` : `margin-top: 0.3rem;`)};
      transform: translateX(-50%)
        ${props => (props.open ? `rotateZ(-45deg)` : ``)};
    }
  }
`
